<template>
    <div class="flex justify-between flex-col lg:flex-row px-[5%] lg:px-[10%] py-[5%] my-[3%] bg-[#F5F8FF] overflow-hidden">
        <div class="w-full lg:w-[40%] text-center lg:text-left mt-[5%]">
            <h1 class="uppercase font-[700] text-[46px] text-[#343637]">
                How to get
                <span class="relative w-[fit-content] inline-block">
                    <span class="absolute top-0 left-0 w-full h-full bg-[#FF6969] -rotate-[6deg]"></span>
                    <span class="relative z-5 text-[#fff]">Started?</span>
                </span>
            </h1>
            <p class="text-[16px] text-[#343637] mt-[5%]">We handle all the complexities of managing a global team, so you can focus on growth!</p>
            <hr class="h-[5px] bg-[#F5F8FF] my-[5%]" />
            <h1 class="text-[16px] text-[#343637]">GO PLATFORM help you find</h1>
            <div class="flex flex-col gap-3 mt-[3%]">
                <h3 class="text-[16px] text-[#343637] flex items-center">
                    <svg class="mr-5" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411618 7.6004 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48946 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2156 19 11.3789 19 9.5C19 6.98044 17.9991 4.56408 16.2175 2.78248C14.4359 1.00089 12.0196 0 9.5 0ZM8.14286 13.2932L4.75 9.90036L5.82893 8.82143L8.14286 11.1354L13.1711 6.10714L14.2541 7.18336L8.14286 13.2932Z"
                            fill="#3361FF"
                        />
                    </svg>
                    The Right Person
                </h3>
                <h3 class="text-[16px] text-[#343637] flex items-center">
                    <svg class="mr-5" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411618 7.6004 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48946 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2156 19 11.3789 19 9.5C19 6.98044 17.9991 4.56408 16.2175 2.78248C14.4359 1.00089 12.0196 0 9.5 0ZM8.14286 13.2932L4.75 9.90036L5.82893 8.82143L8.14286 11.1354L13.1711 6.10714L14.2541 7.18336L8.14286 13.2932Z"
                            fill="#3361FF"
                        />
                    </svg>
                    The Right Skills
                </h3>
                <h3 class="text-[16px] text-[#343637] flex items-center">
                    <svg class="mr-5" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411618 7.6004 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48946 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2156 19 11.3789 19 9.5C19 6.98044 17.9991 4.56408 16.2175 2.78248C14.4359 1.00089 12.0196 0 9.5 0ZM8.14286 13.2932L4.75 9.90036L5.82893 8.82143L8.14286 11.1354L13.1711 6.10714L14.2541 7.18336L8.14286 13.2932Z"
                            fill="#3361FF"
                        />
                    </svg>
                    At the Right Cost
                </h3>
            </div>
        </div>
        <div class="w-[fit-content] hidden md:block">
            <div class="Group1000002879" style="width: 692.15px; height: 646.01px; position: relative">
                <div
                    class="Line39"
                    style="width: 537.75px; height: 0px; left: 346.07px; top: 108.26px; position: absolute; transform: rotate(90deg); transform-origin: 0 0; border: 0.89px #3361ff dotted"
                ></div>
                <div class="Group1000002883" style="width: 373.58px; height: 161.5px; left: 0px; top: 484.51px; position: absolute">
                    <div
                        class="Card7 bg-[#fff] shadow-card hover:shadow-none transition-all duration-300 hover:bg-[#343637] hover:text-[#fff] hover:scale-105"
                        style="width: 274.2px; height: 161.5px; left: 0px; top: 0px; position: absolute; border-radius: 13.57px; overflow: hidden; border: 0.15px #f5f8ff solid"
                    >
                        <div
                            class="Title"
                            style="
                                width: 256.37px;
                                height: 106.32px;
                                left: 9.76px;
                                top: 35.51px;
                                position: absolute;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                gap: 7.54px;
                                display: inline-flex;
                            "
                        >
                            <div class="Text text-left" style="width: 209.62px; height: 106.32px">
                                <span style="font-size: 18.42px; font-family: Lexend; font-weight: 700; line-height: 19.88px; word-wrap: break-word">Pay us Later<br /></span
                                ><span style="font-size: 12px; font-family: Lexend; font-weight: 300; line-height: 19.2px; word-wrap: break-word"
                                    >You can make a payment plan or pay all at once in 6 months.</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="Rectangle6306" style="width: 55.02px; height: 55.02px; left: 318.57px; top: 53.24px; position: absolute; background: white; border-radius: 88.74px"></div>
                    <div
                        class="Text text-left"
                        style="
                            width: 17.75px;
                            height: 21.3px;
                            left: 337.2px;
                            top: 65.1px;
                            position: absolute;
                            text-align: center;
                            color: #3361ff;
                            font-size: 21.3px;
                            font-family: Lexend;
                            font-weight: 700;
                            line-height: 34.08px;
                            word-wrap: break-word;
                        "
                    >
                        4
                    </div>
                </div>
                <div class="Group1000002882" style="width: 373.58px; height: 161.5px; left: 318.56px; top: 323px; position: absolute">
                    <div
                        class="Card6 bg-[#fff] shadow-card hover:shadow-none transition-all duration-300 hover:bg-[#343637] hover:text-[#fff] hover:scale-105"
                        style="width: 274.2px; height: 161.5px; left: 99.39px; top: 0px; position: absolute; border-radius: 13.57px; overflow: hidden; border: 0.15px #f5f8ff solid"
                    >
                        <div
                            class="Title"
                            style="
                                width: 256.37px;
                                height: 106.32px;
                                left: 9.76px;
                                top: 10.51px;
                                position: absolute;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 7.54px;
                                display: inline-flex;
                            "
                        >
                            <div class="Text text-left" style="width: 209.62px; height: 106.32px">
                                <span style="font-size: 18.42px; font-family: Lexend; font-weight: 700; line-height: 19.88px; word-wrap: break-word">Deployment and Support<br /></span
                                ><span style="font-size: 12px; font-family: Lexend; font-weight: 300; line-height: 19.2px; word-wrap: break-word"
                                    >We handle interview scheduling, legalities, onboarding, and talent management to ensure a stress-free recruitment process</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="Rectangle6303" style="width: 55.02px; height: 55.02px; left: 0px; top: 53.24px; position: absolute; background: white; border-radius: 88.74px"></div>
                    <div
                        class="Text text-left"
                        style="
                            width: 17.75px;
                            height: 21.3px;
                            left: 18.63px;
                            top: 65.1px;
                            position: absolute;
                            text-align: center;
                            color: #3361ff;
                            font-size: 21.3px;
                            font-family: Lexend;
                            font-weight: 700;
                            line-height: 34.08px;
                            word-wrap: break-word;
                        "
                    >
                        3
                    </div>
                </div>
                <div class="Group1000002880" style="width: 373.58px; height: 161.5px; left: 0px; top: 161.5px; position: absolute">
                    <div
                        class="Card5 bg-[#fff] shadow-card hover:shadow-none transition-all duration-300 hover:bg-[#343637] hover:text-[#fff] hover:scale-105"
                        style="width: 274.2px; height: 161.5px; left: 0px; top: 0px; position: absolute; border-radius: 13.57px; overflow: hidden; border: 0.15px #f5f8ff solid"
                    >
                        <div
                            class="Title"
                            style="
                                width: 256.37px;
                                height: 106.32px;
                                left: 9.76px;
                                top: 10.51px;
                                position: absolute;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 7.54px;
                                display: inline-flex;
                            "
                        >
                            <div class="Text text-left" style="width: 209.62px; height: 106.32px">
                                <span style="font-size: 18.42px; font-family: Lexend; font-weight: 700; line-height: 19.88px; word-wrap: break-word">Tailored Solutions<br /></span
                                ><span style="font-size: 12px; font-family: Lexend; font-weight: 300; line-height: 15.2px; word-wrap: break-word"
                                    >Our team will filter and vet through a talent pool of skilled experts based on our discovery call to match you with the best-fit profile/s within 72 business
                                    hours</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="Rectangle6304" style="width: 55.02px; height: 55.02px; left: 318.57px; top: 53.24px; position: absolute; background: white; border-radius: 88.74px"></div>
                    <div
                        class="Text text-left"
                        style="
                            width: 17.75px;
                            height: 21.3px;
                            left: 337.2px;
                            top: 65.99px;
                            position: absolute;
                            text-align: center;
                            color: #3361ff;
                            font-size: 21.3px;
                            font-family: Lexend;
                            font-weight: 700;
                            line-height: 34.08px;
                            word-wrap: break-word;
                        "
                    >
                        2
                    </div>
                </div>
                <div class="Group1000002881" style="width: 373.58px; height: 161.5px; left: 318.56px; top: 0px; position: absolute">
                    <div
                        class="Card4 bg-[#fff] shadow-card hover:shadow-none transition-all duration-300 hover:bg-[#343637] hover:text-[#fff] hover:scale-105"
                        style="width: 274.2px; height: 161.5px; left: 99.39px; top: 0px; position: absolute; border-radius: 13.57px; overflow: hidden; border: 0.15px #f5f8ff solid"
                    >
                        <div
                            class="Title"
                            style="
                                width: 256.37px;
                                height: 106.32px;
                                left: 9.76px;
                                top: 10.51px;
                                position: absolute;
                                flex-direction: column;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 7.54px;
                                display: inline-flex;
                            "
                        >
                            <div class="Text text-left" style="width: 209.62px; height: 106.32px">
                                <span style="font-size: 18.42px; font-family: Lexend; font-weight: 700; line-height: 19.88px; word-wrap: break-word">Discovery and Analysis<br /></span
                                ><span style="font-size: 12px; font-family: Lexend; font-weight: 300; line-height: 19.2px; word-wrap: break-word"
                                    >Understanding business needs to get a good grasp on the roles, experience and must-have skills you are looking for..</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="Rectangle6305" style="width: 55.02px; height: 55.02px; left: 0px; top: 53.24px; position: absolute; background: white; border-radius: 88.74px"></div>
                    <div
                        class="Text text-left"
                        style="
                            width: 17.75px;
                            height: 21.3px;
                            left: 18.63px;
                            top: 65.1px;
                            position: absolute;
                            text-align: center;
                            color: #3361ff;
                            font-size: 21.3px;
                            font-family: Lexend;
                            font-weight: 700;
                            line-height: 34.08px;
                            word-wrap: break-word;
                        "
                    >
                        1
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HowToGetStarted",
};
</script>
