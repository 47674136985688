<template>
    <div class="myCard">
        <div class="innerCard">
            <div class="frontSide">
                <p class="title">FRONT SIDE</p>
                <p>Hover Me</p>
            </div>
            <div class="backSide">
                <p class="title">BACK SIDE</p>
                <p>Leave Me</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InterceptionCard",
};
</script>

<style scoped>
.myCard {
    background-color: transparent;
    width: 190px;
    height: 254px;
    perspective: 1000px;
}

.title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin: 0;
}

.innerCard {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    cursor: pointer;
}

.myCard:hover .innerCard {
    transform: rotateY(180deg);
}

.frontSide,
.backSide {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    color: #0f172ac2;
    /* box-shadow: 2px 2px 2px 2px rgb(0, 0, 0); */
    -webkit-box-shadow: -1px 0px 13px -3px rgba(0, 0, 0, 1);
    -moz-box-shadow: -1px 0px 13px -3px rgba(0, 0, 0, 1);
    box-shadow: -1px 0px 13px -3px rgba(0, 0, 0, 1);
    font-weight: 500;
}

.frontSide,
.frontSide::before {
    /* background: linear-gradient(3deg, #2196f3 0%, #fff 100%); */
    background-color: #fff;
}

.backSide,
.backSide::before {
    background-image: linear-gradient(160deg, #0093e9 0%, #fff 100%);
    background-color: #fff;
}

.backSide {
    transform: rotateY(180deg);
}

.frontSide::before,
.backSide::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 110%;
    height: 110%;
    position: absolute;
    z-index: -1;
    border-radius: 1em;
    filter: blur(20px);
    opacity: 0.5;
    /* animation: animate 5s linear infinite; */
}

/* @keyframes animate {
    0% {
        opacity: 0.3;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
} */
</style>
