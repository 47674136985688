<template>
    <div class="interceptionsWrapper">
        <InterceptionCard v-for="(interception, index) in interceptions" :interception="interception" :key="index" />
    </div>
</template>

<script>
import InterceptionCard from "./InterceptionCard.vue";
export default {
    name: "InterceptionsSection",
    components: {
        InterceptionCard,
    },
    data() {
        return {
            interceptions: [
                {
                    assessmentName: "Cognizant GenC - Logical Reasoning",
                    interceptions: [
                        {
                            range: [1, 2],
                            title: "The Developing Logical Reasoner",
                            description:
                                "This candidate is in the early stages of developing their logical reasoning skills. They may benefit from additional practice with logical puzzles, pattern recognition, and deductive reasoning exercises. Improving these skills will aid in better performance in logical reasoning tasks.",
                        },
                        {
                            range: [3, 4],
                            title: "The Proficient Logical Reasoner",
                            description:
                                "This candidate demonstrates a solid understanding of logical reasoning concepts. They are capable of handling most logical reasoning questions effectively but may benefit from practicing more complex problems and refining their deductive reasoning strategies to achieve higher accuracy.",
                        },
                        {
                            range: [5, 6],
                            title: "The Expert Logical Reasoner",
                            description:
                                "This candidate excels in logical reasoning tests, showing exceptional skills in analyzing patterns, making logical deductions, and solving complex reasoning problems. Their expertise is well-suited for roles requiring advanced logical and analytical capabilities.",
                        },
                    ],
                },
                {
                    assessmentName: "Capgemini Aptitude",
                    interceptions: [
                        {
                            range: [1, 6],
                            title: "The Developing Aptitude Tester",
                            description:
                                "This candidate is in the early stages of developing their numerical and logical reasoning skills. They may benefit from additional practice with aptitude tests, focusing on calculations, ratios, and data interpretation. Building these foundational skills will help improve performance in quantitative and analytical tasks.",
                        },
                        {
                            range: [7, 13],
                            title: "The Proficient Aptitude Tester",
                            description:
                                "This candidate shows a solid understanding of numerical and logical reasoning concepts. They are capable of handling most aptitude questions effectively but may benefit from refining their problem-solving strategies and practicing more complex questions to achieve higher accuracy.",
                        },
                        {
                            range: [13, 16],
                            title: "The Expert Aptitude Tester",
                            description:
                                "This candidate excels in aptitude tests, demonstrating strong numerical and logical reasoning skills. They handle complex problems with ease and have a deep understanding of mathematical concepts. Their expertise is well-suited for roles requiring advanced analytical and quantitative capabilities.",
                        },
                    ],
                },
                {
                    assessmentName: "Negotiation 2",
                    interceptions: [
                        {
                            range: [1, 8],
                            title: "The Developing Negotiator",
                            description:
                                "This candidate is in the early stages of developing their negotiation skills. They may need to enhance their preparation, understanding of the other party's position, and approach to making concessions. Focus on building confidence in negotiating strategies, managing emotions, and improving communication skills will be beneficial.",
                        },
                        {
                            range: [8, 15],
                            title: "The Proficient Negotiator",
                            description:
                                "This candidate demonstrates a solid understanding of negotiation principles and practices. They are capable of effective negotiation but may benefit from refining their strategies, improving their ability to handle difficult situations, and exploring creative solutions. Continued development in these areas will help them become an expert negotiator.",
                        },
                        {
                            range: [16, 19],
                            title: "The Expert Negotiator",
                            description:
                                "This candidate excels in negotiation skills, showing strong capabilities in preparation, communication, and conflict resolution. They are adept at handling various negotiation scenarios and achieving favorable outcomes. Their expertise in negotiating is well-suited for complex and high-stakes situations.",
                        },
                    ],
                },
                {
                    assessmentName: "IBM English Language",
                    interceptions: [
                        {
                            range: [1, 6],
                            title: "The Developing Communicator",
                            description:
                                "This candidate is in the early stages of English language proficiency. They may need to improve their grammar, vocabulary, reading comprehension, and writing skills. Focusing on mastering basic grammar rules, expanding vocabulary, and practicing reading comprehension will be beneficial for their language development.",
                        },
                        {
                            range: [7, 12],
                            title: "The Proficient Communicator",
                            description:
                                "This candidate shows a solid grasp of English language skills, including grammar, vocabulary, and comprehension. They are capable of effective communication but may need to refine their skills further to meet the high standards required for roles within IBM. Continued practice and advanced language training will help them reach an expert level.",
                        },
                        {
                            range: [13, 15],
                            title: "The Expert Communicator",
                            description:
                                "This candidate demonstrates exceptional English language proficiency. They have a strong command of grammar, vocabulary, and comprehension skills, making them well-suited for roles requiring advanced language skills within IBM. They are likely to excel in communication and contribute effectively to any team or project.",
                        },
                    ],
                },
                {
                    assessmentName: "Strategic Management Professional",
                    interceptions: [
                        {
                            range: [1, 2],
                            title: "The Developing Strategist",
                            description:
                                "This candidate's skills are in the early stages of strategic management. They may need to improve their understanding of cultural change techniques, strategic planning facilitation, and implementation practices. Focusing on how to integrate strategic plans with operational and financial systems, and understanding the role of accountability and rewards in change initiatives will be beneficial.",
                        },
                        {
                            range: [3, 4],
                            title: "The Proficient Strategist",
                            description:
                                "Your skills are proficient in strategic management. They demonstrate a solid grasp of the techniques for changing culture, facilitating strategic planning, and implementing plans. To reach the expert level, they should further refine their understanding of integrating plans with performance management systems and the nuances of accountability and rewards in supporting change initiatives.",
                        },
                        {
                            range: [4, 5],
                            title: "The Expert Strategist",
                            description:
                                "This candidate's skills are highly developed in strategic management. They have a deep understanding of cultural change techniques, the role of strategic planning facilitators, and successful implementation practices. They effectively integrate strategic plans with organizational systems and understand the importance of both accountability and rewards in supporting change initiatives.",
                        },
                    ],
                },
                {
                    assessmentName: "Customer Service - Hard",
                    interceptions: [
                        {
                            assessmentName: "Adaptability",
                            interceptions: [
                                {
                                    range: [1, 4],
                                    title: "The Developing Adapter",
                                    description:
                                        "This candidate's skills are in the early stages of adaptability. They may struggle with maintaining optimism and flexibility in the face of change. Focus on developing strategies for setting clear goals, imagining new uses for old ideas, and encouraging experimentation to enhance adaptability.",
                                },
                                {
                                    range: [5, 8],
                                    title: "The Proficient Adapter",
                                    description:
                                        "Your skills are proficient in adaptability. They demonstrate a solid ability to shift gears and are on the lookout for new ideas. To reach the expert level, they should further enhance their ability to reinforce change with incentives and view failures as opportunities for innovation.",
                                },
                                {
                                    range: [9, 10],
                                    title: "The Expert Adapter",
                                    description:
                                        "This candidate's skills are highly developed in adaptability. They exhibit a strong ability to maintain optimism, set clear goals, and encourage experimentation. They effectively use incentives to reinforce change and view failures as valuable opportunities for innovation.",
                                },
                            ],
                        },
                        {
                            range: [1, 4],
                            title: "The Novice Service Provider",
                            description:
                                "Your skills are at the early stages of understanding advanced customer service principles. You may have foundational knowledge but would benefit from deeper training and practical experience in handling complex customer interactions and resolving issues effectively.",
                        },
                        {
                            range: [5, 8],
                            title: "The Intermediate Service Provider",
                            description:
                                "Your skills are competent in managing advanced customer service situations. You have a good understanding of effective practices but may need to refine your approach to handle more challenging scenarios and improve overall customer satisfaction.",
                        },
                        {
                            range: [9, 10],
                            title: "The Advanced Service Provider",
                            description:
                                "Your skills are well-developed in advanced customer service. You demonstrate a thorough understanding of complex customer interactions and have a strong ability to resolve issues effectively, ensuring high levels of customer satisfaction and loyalty.",
                        },
                    ],
                },
                {
                    assessmentName: "Customer Service - Easy",
                    interceptions: [
                        {
                            range: [1, 3],
                            title: "The Novice Service Provider",
                            description:
                                "Your skills are at the initial stage of understanding customer service principles. You may have some basic knowledge but could benefit from further training and experience in managing customer expectations and handling various service scenarios.",
                        },
                        {
                            range: [4, 7],
                            title: "The Intermediate Service Provider",
                            description:
                                "Your skills are solid in managing customer service situations. You understand key principles and can effectively handle most customer interactions but may still need to refine your techniques for handling more complex or challenging situations.",
                        },
                        {
                            range: [8, 9],
                            title: "The Advanced Service Provider",
                            description:
                                "Your skills are well-developed in customer service. You demonstrate a thorough understanding of effective practices and principles, and are adept at managing customer expectations, resolving complaints, and ensuring customer satisfaction.",
                        },
                    ],
                },
                {
                    assessmentName: "ENTREPRENEURSHIP - Easy",
                    interceptions: [
                        {
                            range: [1, 8],
                            title: "The Novice Entrepreneur",
                            description:
                                "Your skills are in the early stages of understanding key entrepreneurial concepts. You may have some foundational knowledge but could benefit from further exploration of business ideation, financial literacy, and strategic decision-making.",
                        },
                        {
                            range: [9, 16],
                            title: "The Intermediate Entrepreneur",
                            description:
                                "Your skills are solid, showing a good understanding of fundamental entrepreneurial principles. You have a grasp of business basics and market dynamics but may need to deepen your knowledge to handle more complex entrepreneurial challenges.",
                        },
                        {
                            range: [17, 20],
                            title: "The Advanced Entrepreneur",
                            description:
                                "Your skills are highly developed in foundational entrepreneurship. You demonstrate a thorough understanding of key concepts and are well-prepared to tackle entrepreneurial challenges with a strategic approach.",
                        },
                    ],
                },
                {
                    assessmentName: "Customer Service - Medium",
                    interceptions: [
                        {
                            assessmentName: "SELF MOTIVATION",
                            interceptions: [
                                {
                                    range: [1, 4],
                                    title: "The Emerging Self-Starter",
                                    description:
                                        "Your skills are in the early stages of developing self-motivation. You may find it challenging to consistently apply effort towards your goals and may need to enhance your strategies for overcoming setbacks.",
                                },
                                {
                                    range: [5, 8],
                                    title: "The Motivated Achiever",
                                    description:
                                        "Your skills are solid, showing a good level of self-motivation. You regularly set and work towards your goals and effectively manage setbacks, though there is room for further development.",
                                },
                                {
                                    range: [9, 12],
                                    title: "The Driven Goal-Setter",
                                    description:
                                        "Your skills are highly developed in self-motivation. You consistently set ambitious goals and work diligently towards them, effectively using rewards and managing obstacles to achieve success.",
                                },
                            ],
                        },
                        {
                            range: [1, 2],
                            title: "The Basic Service Provider",
                            description:
                                "This candidate is at the early stages of developing customer service skills. They may need to improve their ability to handle customer interactions with professionalism and sensitivity.",
                        },
                        {
                            range: [3, 4],
                            title: "The Competent Support Specialist",
                            description:
                                "This candidate shows a solid understanding of customer service principles and can manage customer interactions effectively. They are developing their skills and demonstrating an ability to address customer needs appropriately.",
                        },
                        {
                            range: [5, 7],
                            title: "The Advanced Customer Care Expert",
                            description:
                                "This candidate excels in customer service and demonstrates advanced skills in managing and resolving customer concerns. They consistently provide excellent service and handle interactions with high levels of professionalism and empathy.",
                        },
                    ],
                },
                {
                    assessmentName: "Interpersonal Skills",
                    interceptions: [
                        {
                            range: [1, 6],
                            title: "The New Communicator",
                            description:
                                "This candidate is at the beginning stages of developing effective interpersonal skills. They may be working on improving their communication strategies and understanding the nuances of positive interactions in a professional setting.",
                        },
                        {
                            range: [7, 11],
                            title: "The Developing Conversationalist",
                            description:
                                "This candidate shows a good grasp of interpersonal skills and is starting to refine their approach to communication. They are making progress in their ability to build and maintain positive relationships in the workplace.",
                        },
                        {
                            range: [12, 17],
                            title: "The Skilled Interactor",
                            description:
                                "This candidate demonstrates advanced interpersonal skills and excels in building and maintaining effective relationships. They show a strong ability to communicate, empathize, and resolve conflicts, contributing positively to the work environment.",
                        },
                    ],
                },
                {
                    assessmentName: "Amazon Entrepreneurial Assessment",
                    interceptions: [
                        {
                            range: [1, 7],
                            title: "The New Entrepreneur",
                            description:
                                "This candidate is just beginning their journey in understanding the entrepreneurial landscape within Amazon. They might be in the early stages of grasping the complexities of the e-commerce world and exploring what it takes to thrive as an entrepreneur.",
                        },
                        {
                            range: [7, 13],
                            title: "The Developing Innovator",
                            description:
                                "This candidate has a solid grasp of the entrepreneurial skills required for success on Amazon. They are starting to refine their strategies and approaches, showing potential for growth and innovation as they continue to build their business acumen.",
                        },
                        {
                            range: [14, 20],
                            title: "The Accomplished E-Commerce Leader",
                            description:
                                "This candidate exhibits a high level of expertise in navigating the Amazon marketplace. They demonstrate advanced skills in managing and growing their e-commerce ventures, showing a comprehensive understanding of Amazon's tools and strategies for maximizing success.",
                        },
                    ],
                },
                {
                    assessmentName: "Presentation",
                    interceptions: [
                        {
                            range: [1, 6], // Adjusted range for low scorers
                            title: "The Nervous Novice",
                            description:
                                "Individuals in this range may find presenting a daunting task. They might struggle with maintaining eye contact, using gestures, and engaging the audience effectively. With practice and focused development in communication techniques, they can improve their ability to connect with an audience and present more confidently.",
                        },
                        {
                            range: [7, 13], // Mid-range score
                            title: "The Competent Communicator",
                            description:
                                "Those in this range demonstrate moderate presentation skills. They can manage distractions, maintain eye contact, and structure their content well enough to deliver a clear message. However, they still have room to enhance their delivery techniques, including voice modulation, audience interaction, and non-verbal communication.",
                        },
                        {
                            range: [14, 19], // High-range score
                            title: "The Presentation Pro",
                            description:
                                "Individuals with high scores are confident and effective presenters. They captivate their audience with strong eye contact, controlled gestures, and engaging storytelling. Their ability to organize content, handle questions, and adapt to different situations marks them as skilled communicators who can deliver persuasive, impactful presentations.",
                        },
                    ],
                },
                {
                    assessmentName: "Leadership",
                    interceptions: [
                        {
                            range: [1, 8],
                            title: "The Emerging Leader",
                            description:
                                "Individuals in this range are beginning their leadership journey. While they may possess some foundational leadership traits, they are still developing key skills like influencing others and inspiring confidence in their team. With the right guidance and experience, they can evolve into more impactful leaders.",
                        },
                        {
                            range: [9, 16],
                            title: "The Competent Captain",
                            description:
                                "Those in this range demonstrate a solid ability to lead. They can influence and guide others, displaying self-discipline and problem-solving skills. However, there is still room for refinement in areas such as strategic thinking and handling stress. With more experience, they can become more well-rounded leaders.",
                        },
                        {
                            range: [17, 25],
                            title: "The Visionary Leader",
                            description:
                                "Individuals with high scores demonstrate exceptional leadership capabilities. They consistently influence and inspire others, showcasing strong self-discipline, strategic thinking, and the ability to navigate complex challenges. Their ability to develop other leaders and foster collaboration makes them highly effective in driving teams toward success.",
                        },
                    ],
                },
                {
                    assessmentName: "Time Management",
                    interceptions: [
                        {
                            range: [1, 8],
                            title: "The Time Voyager",
                            description:
                                "Individuals in this range may need to embark on a journey to master time. Challenges in prioritizing, scheduling, and meeting deadlines might require navigation skills to sail smoothly through the sea of time.",
                        },
                        {
                            range: [9, 16],
                            title: "The Clock Conductor",
                            description:
                                "Those in this range possess a moderate mastery of time. They can orchestrate tasks and schedules, yet there's room for refinement. With a bit of fine-tuning, they could lead a symphony of productivity.",
                        },
                        {
                            range: [17, 25],
                            title: "The Time Alchemist",
                            description:
                                "Individuals with high scores have an alchemical touch on time. They can transform moments into accomplishments, balancing priorities effortlessly. Their skill at transmuting seconds into success marks them as true time artisans.",
                        },
                    ],
                },
                {
                    assessmentName: "Motivation",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "Spark Seeker",
                            description:
                                "Individuals in this range might be on a quest to discover the sparks that ignite their motivation. The journey involves exploring inner drives and external influences to fuel the flames of inspiration.        ",
                        },
                        {
                            range: [34, 66],
                            title: "The Resilient Dynamo",
                            description:
                                "Those in this range exhibit a moderate level of motivation, displaying resilience in the face of challenges. They have the potential to become dynamic forces by honing their determination and channeling it towards their goals.",
                        },
                        {
                            range: [67, 100],
                            title: "The Soaring Enthusiast",
                            description:
                                "Individuals with high scores are like zeppelins of enthusiasm, soaring to great heights. They possess a sustained, high-flying motivation that propels them towards ambitious goals, making them true enthusiasts in their pursuits.",
                        },
                    ],
                },

                {
                    assessmentName: "Enterpreneurship - Easy",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "Startup Explorer",
                            description:
                                "Individuals in this range are at the starting line of their entrepreneurial journey. They may need to explore the landscape, identify opportunities, and gather the resources required to embark on their business adventure.",
                        },
                        {
                            range: [34, 66],
                            title: "Enterprise Novice",
                            description:
                                "Those in this range have a basic understanding of entrepreneurship. They may need to refine their skills and gain more hands-on experience to evolve from an apprentice into a skilled practitioner in the world of business.",
                        },
                        {
                            range: [67, 100],
                            title: "Startup Maestro",
                            description:
                                "Individuals with high scores are virtuosos in the entrepreneurial symphony. They possess a solid understanding of business principles, strategies, and innovation. Their entrepreneurial prowess positions them as maestros orchestrating successful startups.",
                        },
                    ],
                },

                {
                    assessmentName: "Entrepreneurship - Hard",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "The Business Explorer",
                            description:
                                "viduals in this range are navigating the challenging terrain of entrepreneurship. They may need to explore intricate business landscapes, identify advanced opportunities, and overcome substantial hurdles on their entrepreneurial journey.",
                        },
                        {
                            range: [34, 66],
                            title: "Business Strategist",
                            description:
                                "Those in this range are adept at crafting strategic blueprints for businesses. They possess a nuanced understanding of advanced entrepreneurial principles, requiring them to fine-tune their strategies and architectural skills for optimal business design.",
                        },
                        {
                            range: [67, 100],
                            title: "The Entrepreneurial Genius",
                            description:
                                "Individuals with high scores are geniuses in the art of entrepreneurship. They demonstrate a mastery of intricate business dynamics, innovation, and risk management. Their entrepreneurial acumen positions them as savants capable of successfully launching and managing complex startups.",
                        },
                    ],
                },
                {
                    assessmentName: "Entrepreneurship - Hard",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "The Business Explorer",
                            description:
                                "viduals in this range are navigating the challenging terrain of entrepreneurship. They may need to explore intricate business landscapes, identify advanced opportunities, and overcome substantial hurdles on their entrepreneurial journey.",
                        },
                        {
                            range: [34, 66],
                            title: "Business Strategist",
                            description:
                                "Those in this range are adept at crafting strategic blueprints for businesses. They possess a nuanced understanding of advanced entrepreneurial principles, requiring them to fine-tune their strategies and architectural skills for optimal business design.",
                        },
                        {
                            range: [67, 100],
                            title: "The Entrepreneurial Genius",
                            description:
                                "Individuals with high scores are geniuses in the art of entrepreneurship. They demonstrate a mastery of intricate business dynamics, innovation, and risk management. Their entrepreneurial acumen positions them as savants capable of successfully launching and managing complex startups.",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style scoped>
.interceptionsWrapper {
    width: 100%;
    padding: 3% 5%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 1rem;
    flex-direction: row;
}
</style>
