<template>
    <div class="relative w-full px-[5%] lg:px-[8%] py-3 overflow-hidden">
        <!-- <div class="absolute right-[-200px] top-[-228px] -z-10">
            <svg width="456" height="456" viewBox="0 0 456 456" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="228" cy="228" r="228" fill="#E0DBFF" />
            </svg>
        </div> -->
        <div class="absolute right-[-710px] z-[10] top-[-228px]">
            <svg width="1244" height="1315" viewBox="0 0 1244 1315" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1241 657.213C1241 1018.93 963.623 1311.93 621.75 1311.93C279.876 1311.93 2.5 1018.93 2.5 657.213C2.5 295.492 279.876 2.5 621.75 2.5C963.623 2.5 1241 295.492 1241 657.213Z"
                    stroke="#5236FF"
                    stroke-opacity="0.05"
                    stroke-width="5"
                />
            </svg>
        </div>
        <div class="absolute right-[-500px] z-[10] top-[-90px]">
            <svg width="869" height="919" viewBox="0 0 869 919" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M866.5 459.211C866.5 711.472 673.07 915.73 434.75 915.73C196.43 915.73 3 711.472 3 459.211C3 206.951 196.43 2.69336 434.75 2.69336C673.07 2.69336 866.5 206.951 866.5 459.211Z"
                    stroke="#5236FF"
                    stroke-opacity="0.05"
                    stroke-width="5"
                />
            </svg>
        </div>
        <h1 class="uppercase font-[600] text-[20px] mt-[5%] text-[#333333] my-[3%] text-left">How it works</h1>
        <div class="flex flex-col lg:flex-row justify-between items-start">
            <div class="w-full lg:w-[40%] text-left">
                <h1 class="uppercase font-[700] text-[36px] text-[#333333]">
                    hire talents from africa in
                    <span class="relative w-[fit-content] inline-block">
                        <span class="absolute top-0 left-0 w-full h-full bg-[#E0DBFF] -rotate-[6deg] -z-10"></span>
                        <span class="relative z-5">72h only.</span>
                    </span>
                </h1>

                <div class="mt-[10%] md:mt-[5%]">
                    <div class="mt-[5%]">
                        <h1 class="text-[#5D5FEF] duration-300 hover:font-[700] text-[20px] font-[600]">
                            <span class="font-[700] text-[22px]">01</span>
                            Cost Efficient Talents
                        </h1>
                        <p class="text-[16px] text-[#333333]">Hiring talents from Africa will Reduce your development expenses with at least 75%.</p>
                    </div>
                    <div class="mt-[5%]">
                        <h1 class="text-[#5D5FEF] duration-300 hover:font-[700] text-[20px] font-[600]">
                            <span class="font-[700] text-[22px]">02</span>
                            Vetted Talents
                        </h1>
                        <p class="text-[16px] text-[#333333]">Our Talent pool are ready to go, we have vetted their background, skills and personality.</p>
                    </div>
                    <div class="mt-[5%]">
                        <h1 class="text-[#5D5FEF] duration-300 hover:font-[700] text-[20px] font-[600]">
                            <span class="font-[700] text-[22px]">03</span>
                            30 Days Guarantee
                        </h1>
                        <p class="text-[16px] text-[#333333]">We offer a free replacement for every hiring for the first 30 days, consider it as a try out experience.</p>
                    </div>
                </div>
            </div>
            <div class="flex items-end justify-end h-full relative mt-[0%] md:mt-0">
                <div
                    class="text-[#fff] bg-[#8C8AFF] absolute top-0 left[-20px] md:left-[-30px] w-[100px] h-[100px] md:w-[150px] md:h-[150px] rounded-full flex items-center justify-center flex-col p-4"
                >
                    <h1 class="font-[700] text-[16px] md:text-[20px]">32+</h1>
                    <h1 class="text-[16px]">Years of experience</h1>
                </div>
                <img src="../assets/landing-page-logos/africa.svg" alt="africa" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HowItWorks",
};
</script>
